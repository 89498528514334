/* eslint-disable handle-callback-err */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useEffect, useContext } from 'react'
import { Table } from 'react-bootstrap'
import axios from 'axios'
import Auth from '../../lib/Auth.js'
import '../../style/container/objectives.scss'
import MyDeptObjModal from '../presentational/UserObjectivesModals/MyDeptObjModal.jsx'
import EditMyDeptObjModal from '../presentational/UserObjectivesModals/EditMyDeptObjModal.jsx'
import MyDeptKPIModal from '../presentational/UserObjectivesModals/MyDeptKPIModal.jsx'
import EditMyDeptKPIModal from '../presentational/UserObjectivesModals/EditMyDeptKPIModal.jsx'

import MyObjModal from '../presentational/UserObjectivesModals/MyObjModal.jsx'
import EditMyObjModal from '../presentational/UserObjectivesModals/EditMyObjModal.jsx'

import MyKPIModal from '../presentational/UserObjectivesModals/MyKPIModal.jsx'
import EditMyKPIModal from '../presentational/UserObjectivesModals/EditMyKPIModal.jsx'

import MyTasksModal from '../presentational/UserObjectivesModals/MyTasksModal.jsx'
import EditMyTasksModal from '../presentational/UserObjectivesModals/EditMyTasksModal.jsx'

import MyObjectivesTable from './MyObjectivesTable.jsx'
import MyKpiTable from './MyKpiTable.jsx'
import StrategicObjectivesTables from './admin/OrganisationStrategyTables/StrategicObjectivesTable.jsx'
import StrategicIndicatorsTable from './admin/OrganisationStrategyTables/StrategicIndicatorsTable.jsx'
import MyTasksTable from '../presentational/MyTasksTable.jsx'
import * as moment from 'moment'
import Swal from 'sweetalert2'
import { InfoToolTip } from '../common/InfoTooltip.jsx'
import filter from 'lodash/fp/filter'
import toInteger from 'lodash/fp/toInteger'
import userContext from '../../context/userContext'

import Axios from 'axios'
import { notification } from 'antd'
import { uploadFile } from '../../common/uploadFile.js'

export default function ObjectivesTables(props) {
  const { sessionDetails } = useContext(userContext)

  const [fileList, uploadFileList] = useState([])
  const [tabTitle, setTabTitle] = useState('')
  const [tabData, setTabData] = useState([])
  const [tabLabel, setTabLabel] = useState([])
  const [pickModal, setPickModal] = useState('DepartmentObjectives')
  const [showModal, setShowModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)

  const [users, setUsers] = useState([])
  const [frequency, setFrequency] = useState([])
  const [strategicObjectives, setStrategicObjectives] = useState([])
  const [departmentalObjectives, setDepartmentalObjectives] = useState([])
  const [personalObjectives, setPersonalObjectives] = useState([])
  const [strategicIndicators, setStrategicIndicators] = useState([])
  const [departmentalIndicators, setDepartmentalIndicators] = useState([])
  const [personalIndicators, setPersonalIndicators] = useState([])
  const [actionPriority, setPriority] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [editItem, setEditItem] = useState(null)
  const [uploadDoc, setUploadDoc] = useState({})

  useEffect(() => {
    getUsers()
    fetchData()
  }, [])

  const fetchData = (tableName) => {
    let userData = {}
    axios
      .get(BASE_URL + `/organisations/${props.orgId}/users`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        setUsers(res.data)
      })
    axios
      .get(BASE_URL + `/organisations/${props.orgId}/frequencyOfReviews`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        setFrequency(res.data)
      })

    axios
      .get(BASE_URL + `/objectives`, {
        params: {
          organisation: props.orgId,
          //objectiveType: 'PERSONAL',
          lead: Auth.getLoggedUserId(),
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        setPersonalObjectives(res.data)
      })

    axios
      .get(BASE_URL + `/indicators`, {
        params: {
          organisation: props.orgId,
          // objectiveType: 'PERSONAL',
          lead: Auth.getLoggedUserId(),
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        setPersonalIndicators(res.data)
      })

    axios
      .get(BASE_URL + `/organisations/${props.orgId}/priorities`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        setPriority(res.data)
      })

    if (tableName !== '') {
      setPickModal('')
      setPickModal(tableName)
    }

    setLoaded(true)
  }

  useEffect(() => {
    if (sessionDetails?.org) {
      getUsers()
    }
    setTabTitle(props.tableTitle)
    setTabData(props.tableData.data)
    setTabLabel(props.tableData.heading)
    setPickModal(props.modalName)
  }, [props])

 const getUsers = async () => {
  try {
    const usersResponse = await axios.get(
      `${BASE_URL}/organisations/${props.orgId}/users`, 
      {
        headers: { Authorization: `Bearer ${Auth.getToken()}` }
      }
    );

    if (usersResponse.status !== 200) {
      throw new Error('Failed to fetch users');
    }

    setUsers(usersResponse.data);
    
    const depId = usersResponse.data.find(
      (data) => data.id === Auth.getLoggedUserId()
    )?.department?.id;
    
    if (!depId) return;

    const fetchRequests = [];
    const headers = { Authorization: `Bearer ${Auth.getToken()}` };

    if (sessionDetails?.org?.isOrgOwner) {
      // Strategic objectives and indicators
      const params = {
        organisation: props.orgId,
        objectiveType: 'STRATEGIC'
      };

      fetchRequests.push(
        axios.get(`${BASE_URL}/objectives`, { params, headers })
          .then(res => setStrategicObjectives(res.data)),
        axios.get(`${BASE_URL}/indicators`, { params, headers })
          .then(res => setStrategicIndicators(res.data))
      );
    } else {
      const pDept = sessionDetails?.org?.parentDepartment?.department?.id;
      if (pDept) {
        // Department objectives and indicators for parent
        const params = {
          organisation: props.orgId,
          objectiveType: 'DEPARTMENT',
          department: pDept
        };

        fetchRequests.push(
          axios.get(`${BASE_URL}/objectives`, { params, headers })
            .then(res => setStrategicObjectives(res.data)),
          axios.get(`${BASE_URL}/indicators`, { params, headers })
            .then(res => setStrategicIndicators(res.data))
        );
      }
    }

    // Departmental objectives and indicators
    const deptParams = {
      organisation: props.orgId,
      objectiveType: 'DEPARTMENT',
      department: depId
    };

    fetchRequests.push(
      axios.get(`${BASE_URL}/objectives`, { params: deptParams, headers })
        .then(res => setDepartmentalObjectives(res.data)),
      axios.get(`${BASE_URL}/indicators`, { params: deptParams, headers })
        .then(res => setDepartmentalIndicators(res.data))
    );

    await Promise.all(fetchRequests);
  } catch (error) {
    console.error('Error in getUsers:', error.message);
    // Handle error appropriately (e.g., show error notification)
    throw error;
  }
};

 const userObjectives = async () => {
  try {
    const headers = { Authorization: `Bearer ${Auth.getToken()}` };
    const fetchRequests = [];

    if (sessionDetails?.org?.isOrgOwner) {
      const strategicParams = {
        organisation: props.orgId,
        objectiveType: 'STRATEGIC'
      };
      
      fetchRequests.push(
        axios.get(`${BASE_URL}/objectives`, { 
          params: strategicParams,
          headers 
        })
        .then(res => setStrategicObjectives(res.data))
      );
    }

    if (depId?.department?.id) {
      const departmentParams = {
        organisation: props.orgId,
        objectiveType: 'DEPARTMENT',
        department: depId.department.id
      };

      fetchRequests.push(
        axios.get(`${BASE_URL}/objectives`, {
          params: departmentParams,
          headers
        })
        .then(res => setDepartmentalObjectives(res.data))
      );
    }

    await Promise.all(fetchRequests);

  
    if (res?.data) {
      setUsers({
        users: res.data,
        loggedIndepartment: depId
      });
    }
  } catch (error) {
    console.error('Error in userObjectives:', error.message);
    throw error;
  }
};

  // Renders table heading. Dont modify this, it works
  const renderTableHeading = () => {
    return tabLabel.map((item) => {
      return <th>{item.label}</th>
    })
  }

  // Rendering rows of dummy data
  const renderTableData = () => {
    return tabData.map((item) => {
      const tabData = []
      for (const [key, value] of Object.entries(item)) {
        if (key !== 'id' && key !== 'lead' && key !== 'priority')
          tabData.push(<td>{value}</td>)
        if (key !== 'id' && key === 'lead') {
          tabData.push(
            <td>
              {value}
              <span className="buttons-container">
                <button>
                  <i className="fa fa-pencil"></i>
                </button>
                <button>
                  <i className="fa fa-trash"></i>
                </button>
              </span>
            </td>
          )
        }

        if (key !== 'id' && key === 'priority') {
          tabData.push(
            <td>
              {value}
              <span className="buttons-container">
                <button>
                  <i className="fa fa-pencil"></i>
                </button>
                <button>
                  <i className="fa fa-trash"></i>
                </button>
              </span>
            </td>
          )
        }
      }
      return <tr>{tabData}</tr>
    })
  }

  const modalCloseHandler = () => {
    setShowModal(false)
  }

  const submitDeptObjForm = (e, data) => {
    const formData = { ...data }
    formData.startDt = moment(formData.startDt, 'DD/MM/YY').format('YYYY-MM-DD')
    formData.targetEndDt = moment(formData.endDt, 'DD/MM/YY').format(
      'YYYY-MM-DD'
    )
    let startDate = new Date(formData.startDt)
    let endDate = new Date(formData.targetEndDt)
    if (endDate < startDate) {
      return Swal.fire(
        'Failed',
        'End date must be greater than start date',
        'error'
      )
    }
    delete formData.objective
    let userDepartment = 0
    users.map((user) => {
      if (toInteger(user.id) === toInteger(formData.lead)) {
        userDepartment = user.department.id
      }
    })
    let filterOBJ =
      users &&
      users.find((data) => {
        return toInteger(data.id) === toInteger(formData.lead)
      })
    axios
      .post(
        BASE_URL + `/objectives`,
        {
          ...formData,
          ref: Math.floor(Math.random() * 1000),
          department: sessionDetails?.user?.department?.id,
          team: 0,
          organisation: props.orgId,
          objectiveType: 'DEPARTMENT',
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setShowModal(false)
          fetchData('DepartmentObjectives')
        }
      })
  }

  const submitEditDeptObjForm = (e, data) => {
    data.startDt = moment(data.startDt, 'DD/MM/YY').format('YYYY-MM-DD')
    data.targetEndDt = moment(data.targetEndDt, 'DD/MM/YY').format('YYYY-MM-DD')
    let startDate = new Date(data.startDt)
    let endDate = new Date(data.targetEndDt)
    if (endDate < startDate) {
      return Swal.fire(
        'Failed',
        'End date must be greater than start date',
        'error'
      )
    }
    axios
      .put(
        BASE_URL + `/objectives/${data.id}`,
        {
          ...data,
          department: sessionDetails?.user?.department?.id,
          team: 0,
          organisation: props.orgId,
          objectiveType: 'DEPARTMENT',
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setShowEditModal(false)
          setEditItem(null)
          fetchData('DepartmentObjectives')
        }
      })
  }

  const submitMyObjForm = (e, data) => {
    const formData = { ...data }
    formData.startDt = moment(formData.startDt, 'DD-MM-YY').format('YYYY-MM-DD')
    formData.endDt = moment(formData.endDt, 'DD-MM-YY').format('YYYY-MM-DD')
    delete formData.objective
    let startDate = new Date(formData.startDt)
    let endDate = new Date(formData.endDt)
    if (endDate < startDate) {
      return Swal.fire(
        'Failed',
        'End date must be greater than start date',
        'error'
      )
    }
    axios
      .post(
        BASE_URL + `/objectives`,
        {
          ...formData,
          ref: Math.floor(Math.random() * 1000),
          department: 0,
          team: 0,
          targetEndDt: formData.endDt,
          organisation: props.orgId,
          lead: Auth.getLoggedUserId(),
          objectiveType: 'PERSONAL',
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setShowModal(false)
          fetchData('Objectives')
        }
      })
  }

  const submitEditMyObjForm = (e, data) => {
    const formData = { ...data }
    formData.startDt = moment(formData.startDt, 'DD-MM-YY').format('YYYY-MM-DD')
    formData.startDate = formData.startDt
    formData.targetEndDt = moment(formData.targetEndDt, 'DD-MM-YY').format(
      'YYYY-MM-DD'
    )
    formData.targetEndDate = formData.targetEndDt
    let startDate = new Date(formData.startDt)
    let endDate = new Date(formData.targetEndDt)
    if (endDate < startDate) {
      return Swal.fire(
        'Failed',
        'End date must be greater than start date',
        'error'
      )
    }
    axios
      .put(
        BASE_URL + `/objectives/${data.id}`,
        {
          ...formData,
          department: 0,
          team: 0,
          organisation: props.orgId,
          objectiveType: 'PERSONAL',
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setShowEditModal(false)
          setEditItem(null)
          fetchData('Objectives')
        }
      })
  }

  const submitDeptKPIForm = (e, data) => {
    // let reviewFrequency = Number(formData).reviewFrequency
    data.startDt = moment(data.startDt, 'DD/MM/YY').format('YYYY-MM-DD')
    data.endDt = moment(data.endDt, 'DD/MM/YY').format('YYYY-MM-DD')
    let startDate = new Date(data.startDt)
    let endDate = new Date(data.endDt)
    if (endDate < startDate) {
      return Swal.fire(
        'Failed',
        'End date must be greater than start date',
        'error'
      )
    }
    const formData = {
      ...data,
      ref: Math.floor(Math.random() * 1000),
      department: sessionDetails?.user?.department?.id,
      team: 0,
      organisation: props.orgId,
      objectiveType: 'DEPARTMENT',
    }

    axios
      .post(
        BASE_URL + `/indicators`,
        {
          ...formData,
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setShowModal(false)
          fetchData('DepartmentKPI')
        }
      })
  }

  const submitEditDeptKPIForm = (e, data) => {
    const formData = {
      ...data,
      department: sessionDetails?.user?.department?.id,
      team: 0,
      organisation: props.orgId,
      objectiveType: 'DEPARTMENT',
      reviewFrequency: data.frequencyOfReview,
    }
    formData.startDt = moment(formData.startDt, 'DD/MM/YY').format('YYYY-MM-DD')
    formData.endDt = moment(formData.endDt, 'DD/MM/YY').format('YYYY-MM-DD')
    let startDate = new Date(formData.startDt)
    let endDate = new Date(formData.endDt)
    if (endDate < startDate) {
      return Swal.fire(
        'Failed',
        'End date must be greater than start date',
        'error'
      )
    }
    delete formData.frequencyOfReview
    axios
      .put(
        BASE_URL + `/indicators/${data.id}`,
        {
          ...formData,
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setShowEditModal(false)
          setEditItem(null)
          fetchData('DepartmentKPI')
        }
      })
  }
  const submitMyKPIForm = (e, data) => {
    const formData = {
      ...data,
      ref: Math.floor(Math.random() * 1000),
      department: 95,
      weight: 20,
      team: 0,
      organisation: props.orgId,
      objectiveType: 'PERSONAL',
    }

    formData.startDt = moment(formData.startDt, 'DD-MM-YY').format('YYYY-MM-DD')
    formData.endDt = moment(formData.endDt, 'DD-MM-YY').format('YYYY-MM-DD')
    let startDate = new Date(formData.startDt)
    let endDate = new Date(formData.endDt)
    if (endDate < startDate) {
      return Swal.fire(
        'Failed',
        'End date must be greater than start date',
        'error'
      )
    }
    axios
      .post(
        BASE_URL + `/indicators`,
        {
          ...formData,
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setShowModal(false)
          fetchData('KPI')
        }
      })
  }

  const submitEditMyKPIForm = (e, data) => {
    const formData = {
      ...data,
      department: 95,
      team: 0,
      organisation: props.orgId,
      objectiveType: 'PERSONAL',
      reviewFrequency: data.frequencyOfReview,
    }
    formData.startDt = moment(formData.startDt, 'DD-MM-YY').format('YYYY-MM-DD')
    formData.endDt = moment(formData.endDt, 'DD-MM-YY').format('YYYY-MM-DD')
    let startDate = new Date(formData.startDt)
    let endDate = new Date(formData.endDt)
    if (endDate < startDate) {
      return Swal.fire(
        'Failed',
        'End date must be greater than start date',
        'error'
      )
    }
    axios
      .put(
        BASE_URL + `/indicators/${data.id}`,
        {
          ...formData,
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setShowEditModal(false)
          setEditItem(null)
          fetchData('KPI')
        }
      })
  }

  const submitTaskForm = (e, data) => {
    data.dueDt = moment(data.dueDt, 'DD-MM-YY').format('YYYY-MM-DD')
    data.startDt = moment(data.startDt, 'DD-MM-YY').format('YYYY-MM-DD')
    data.reminder = moment(data.reminder, 'DD-MM-YY').format('YYYY-MM-DD')
    let startDate = new Date(data.startDt)
    let endDate = new Date(data.dueDt)
    if (endDate < startDate) {
      return Swal.fire(
        'Failed',
        'End date must be greater than start date',
        'error'
      )
    }
    axios
      .post(
        BASE_URL + `/organisations/${props.orgId}/tasks`,
        {
          ...data,
          startDate: data.startDt,
          dueDate: data.dueDt,
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setShowModal(false)
          // debugger
          console.log('uploadDoc', uploadDoc, res)

          let uploadReq = {
            description: '',
            user: sessionDetails?.user?.id,
            department: sessionDetails?.user?.department?.id,
            organisation: props.orgId,
            fileType: 'TASK',
            taskId: data.id,
            treatmentId: 0,
            validFileType: true,
          }
          uploadFile(uploadDoc, props.orgId, uploadReq)
          setUploadDoc([])
          fetchData('Tasks')
        } else {
          return Swal.fire(
            'Failed',
            'There was a problem adding your task. Please try again',
            'error'
          )
        }
      })
      .catch((res) => {
        return Swal.fire(
          'Failed',
          'There was a problem adding your task. Please try again',
          'error'
        )
      })
  }

  const submitEditMyTasksForm = (e, data) => {
    data.dueDt = moment(data.dueDt, 'DD-MM-YY').format('YYYY-MM-DD')
    data.startDt = moment(data.startDt, 'DD-MM-YY').format('YYYY-MM-DD')
    data.reminder = moment(data.reminder, 'DD-MM-YY').format('YYYY-MM-DD')
    let startDate = new Date(data.startDt)
    let endDate = new Date(data.dueDt)
    if (endDate < startDate) {
      return Swal.fire(
        'Failed',
        'End date must be greater than start date',
        'error'
      )
    }
    axios
      .put(
        BASE_URL + `/organisations/${props.orgId}/tasks/${data.id}`,
        {
          ...data,
          startDate: data.startDt,
          dueDate: data.dueDt,
        },
        {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setShowEditModal(false)
          setEditItem(null)
          let uploadReq = {
            description: '',
            user: sessionDetails?.user?.id,
            department: sessionDetails?.user?.department?.id,
            organisation: props.orgId,
            fileType: 'TASK',
            taskId: data.id,
            treatmentId: 0,
            validFileType: true,
          }
          uploadFile(uploadDoc, props.orgId, uploadReq)
          setUploadDoc([])
          fetchData('Tasks')
        } else {
          return Swal.fire(
            'Failed',
            'There was a problem adding your task. Please try again',
            'error'
          )
        }
      })
      .catch((err) => {
        return Swal.fire(
          'Failed',
          'There was a problem adding your task. Please try again',
          'error'
        )
      })
  }

  // Conditionally binding modals to their respective tables
  const renderModal = () => {
    switch (pickModal) {
      case 'DepartmentObjectives':
        return (
          <div>
            <MyDeptObjModal
              users={users}
              frequency={frequency}
              objectives={strategicObjectives}
              show={showModal}
              submitForm={submitDeptObjForm}
              handleClose={() => {
                setShowModal(false)
              }}
            />
          </div>
        )
      case 'DepartmentKPI':
        return (
          <div>
            <MyDeptKPIModal
              users={users}
              frequency={frequency}
              indicators={strategicIndicators}
              show={showModal}
              submitForm={submitDeptKPIForm}
              handleClose={() => {
                setShowModal(false)
              }}
            />
          </div>
        )
      case 'Objectives':
        return (
          <div>
            <MyObjModal
              users={users}
              frequency={frequency}
              objectives={departmentalObjectives}
              show={showModal}
              submitForm={submitMyObjForm}
              handleClose={() => {
                setShowModal(false)
              }}
            />
          </div>
        )
      case 'KPI':
        return (
          <div>
            <MyKPIModal
              users={users}
              frequency={frequency}
              objectives={departmentalObjectives}
              indicators={departmentalIndicators}
              show={showModal}
              submitForm={submitMyKPIForm}
              handleClose={() => {
                setShowModal(false)
              }}
            />
          </div>
        )
      case 'Tasks':
        return (
          <div>
            <MyTasksModal
              handleDocUpload={handleDocUpload}
              show={showModal}
              assignee={users[0]}
              priorities={actionPriority}
              handleSubmit={submitTaskForm}
              handleClose={() => {
                setShowModal(false)
              }}
            />
          </div>
        )
      default:
        return (
          <div>
            <p>Nothing to show here!</p>
          </div>
        )
    }
  }

  const handleDocUpload = ({ fileList }) => {
    let fileData = fileList.length > 0 && fileList[0]
    if (fileList.length !== 0) {
      setUploadDoc(fileList)
      // updateProfile(fileData)
    }
    // uploadFileList({ fileList })
  }

  const updateProfile = (fileData) => {
    try {
      Axios.post(BASE_URL + `/organisations/${props.orgId}/document/upload`, {
        fileName: fileData.name,
        size: fileData.size,
      }).then((res) => {
        let { data } = res

        uploadImageToServer(data?.fileUrl, fileData)
      })
    } catch (e) {
      console.log(e)
    }
  }

  const uploadImageToServer = async (url, file) => {
    try {
      let myHeaders = new Headers()
      myHeaders.append('Content-Type', file.type)
      myHeaders.append('Access-Control-Allow-Origin', '*')

      await fetch(url, {
        method: 'PUT',
        headers: myHeaders,
        body: file.originFileObj,
      })
        .then((response) => response.text())
        .then((result) => {
          notification.success({
            message: 'Successful',
            description: 'Uploaded Successfully',
          })
        })
        .catch((error) => console.log('error', error))
    } catch (e) {
      console.log(e)
    }
  }

  const renderEditModal = () => {
    switch (pickModal) {
      case 'DepartmentObjectives':
        return (
          <div>
            <EditMyDeptObjModal
              users={users}
              frequency={frequency}
              objectives={strategicObjectives}
              show={showEditModal}
              submitForm={submitEditDeptObjForm}
              handleClose={() => {
                setShowEditModal(false)
              }}
              item={editItem}
            />
          </div>
        )
      case 'DepartmentKPI':
        return (
          <div>
            <EditMyDeptKPIModal
              users={users}
              frequency={frequency}
              indicators={strategicIndicators}
              show={showEditModal}
              submitForm={submitEditDeptKPIForm}
              handleClose={() => {
                setShowEditModal(false)
              }}
              item={editItem}
            />
          </div>
        )
      case 'Objectives':
        return (
          <div>
            <EditMyObjModal
              users={users}
              frequency={frequency}
              objectives={departmentalObjectives}
              show={showEditModal}
              submitForm={submitEditMyObjForm}
              handleClose={() => {
                setShowEditModal(false)
              }}
              item={editItem}
            />
          </div>
        )
      case 'KPI':
        return (
          <div>
            <EditMyKPIModal
              users={users}
              frequency={frequency}
              objectives={departmentalObjectives}
              indicators={departmentalIndicators}
              show={showEditModal}
              submitForm={submitEditMyKPIForm}
              handleClose={() => {
                setShowEditModal(false)
              }}
              item={editItem}
            />
          </div>
        )
      case 'Tasks':
        return (
          <div>
            <EditMyTasksModal
              handleDocUpload={handleDocUpload}
              show={showEditModal}
              assignee={users[0]}
              priorities={actionPriority}
              submitForm={submitEditMyTasksForm}
              handleClose={() => {
                setShowEditModal(false)
              }}
              item={editItem}
            />
          </div>
        )
      default:
        return (
          <div>
            <p>Nothing to show here!</p>
          </div>
        )
    }
  }

  const handleEdit = (item, show) => {
    setEditItem(item)
    setShowEditModal(true)
  }

  if (!loaded) {
    return 'Loading...'
  } else {
    return (
      <div className="px-5 my-5">
        <h4 style={{ color: '#33466B' }}>
          <InfoToolTip
            title={props.tableTitle}
            text={props.tableTitle + 'Info text'}
          ></InfoToolTip>
        </h4>
        {Auth.getRole() !== 'View' && (
          <p>
            Add New{' '}
            <i
              className="fa fa-plus"
              style={{ color: '#4CAF50' }}
              onClick={() => {
                setShowModal(true)
              }}
            />
          </p>
        )}

        {pickModal === 'DepartmentObjectives' && (
          <StrategicObjectivesTables
            showHeadings={false}
            tableHeading={'Strategic Objective'}
            objectiveType="DEPARTMENT"
            orgId={props.orgId}
            users={users}
            goals={[]}
            objEditModal={true}
            editItem={(item) => handleEdit(item, true)}
            departmentId={sessionDetails?.user?.department?.id}
          />
        )}

        {pickModal === 'DepartmentKPI' && (
          <StrategicIndicatorsTable
            showHeadings={false}
            tableHeading={'Strategic Indicator'}
            indicatorType="DEPARTMENT"
            orgId={props.orgId}
            users={users}
            goals={[]}
            objEditModal={true}
            editItem={(item) => handleEdit(item, true)}
          />
        )}

        {pickModal === 'Objectives' && (
          <MyObjectivesTable
            tableHeading={'Departmental Objective'}
            showHeadings={false}
            //objectiveType="PERSONAL"
            orgId={props.orgId}
            users={users}
            goals={[]}
            objEditModal={true}
            editItem={(item) => handleEdit(item, true)}
          />
        )}

        {pickModal === 'KPI' && (
          <MyKpiTable
            tableHeading={'Departmental Indicator'}
            showHeadings={false}
            indicatorType="PERSONAL"
            orgId={props.orgId}
            users={users}
            goals={[]}
            objEditModal={true}
            editItem={(item) => handleEdit(item, true)}
          />
        )}

        {pickModal === 'Tasks' && (
          <MyTasksTable
            showHeadings={false}
            showActions={true}
            tableHeading={'My Tasks'}
            orgId={props.orgId}
            users={users}
            objEditModal={true}
            editItem={(item) => handleEdit(item, true)}
          />
        )}

        {showModal && renderModal()}
        {showEditModal && renderEditModal()}
      </div>
    )
  }
}
