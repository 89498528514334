import React, { useState, useContext, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { DownloadOutlined } from '@ant-design/icons'
import Axios from 'axios'
import moment from 'moment'

import '../../../style/container/organisation.scss'
import userContext from '../../../context/userContext'
import { uploadFile } from '../../../common/uploadFile'

const CorporateDocumentsTable = (props) => {
  const [showMore, setShowMore] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [docData, setDocData] = useState([])

  const { sessionDetails } = useContext(userContext)

  console.log('SESSION DETAILS CORP DOC', sessionDetails)

  useEffect(() => {
    getAllDocuments()
  }, [])

  const DocumentsData = {
    heading: [
      { id: 1, label: 'File Name' },
      { id: 2, label: 'Description' },
      { id: 3, label: 'File Size' },
      { id: 4, label: 'Date Uploaded' },
      { id: 5, label: 'Action' },
    ],
  }

  const renderTableHeading = () => {
    return DocumentsData.heading.map((item) => {
      return <th>{item.label}</th>
    })
  }

  /*   const deleteUploadFile = async (docId, orgId) => {
    try {
      const data = await deleteFile(orgId, docId)
      console.log('DEL DATA --->', data)
      getAllDocuments()
    } catch (e) {
      console.log(e)
    }
  } */

  //Rendering rows of dummy data
  const renderTableData = () => {
    return docData?.map((item, key) => {
      return (
        <tr key={key}>
          <td>{item.fileName}</td>
          <td>{item.description}</td>
          <td>{bytesToSize(item.size)}</td>
          <td>
            {item.uploadDate && moment(item.uploadDate).format('DD-MM-YY')}
          </td>
          <td>
            <a
              className="cursor-pointer"
              href={item.fileUrl}
              download
              target="_blank"
            >
              <DownloadOutlined />
            </a>
          </td>
        </tr>
      )
    })
  }

  const bytesToSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  const renderSlicedTableData = () => {
    let slicedData = docData?.slice(0, 5)
    return slicedData.map((item, key) => {
      return (
        <tr key={key}>
          <td>{item.fileName}</td>
          <td>{item.description}</td>
          <td>{bytesToSize(item.size)}</td>
          <td>
            {item.uploadDate && moment(item.uploadDate).format('DD-MM-YY')}
          </td>
          <td>
            <a
              className="cursor-pointer"
              href={item.fileUrl}
              download
              target="_blank"
            >
              <DownloadOutlined />
            </a>
          </td>
        </tr>
      )
    })
  }
  const showMoreClickHandler = () => {
    setShowMore(!showMore)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const onFinish = (data) => {
    try {
      let uploadReq = {
        fileName: data.fileName,
        description: data.description,
        uploadDate: data?.uploadFile?.lastModifiedDate,
        size: data?.uploadFile?.size,
        user: sessionDetails?.user?.id,
        department: sessionDetails?.user?.department?.id,
        organisation: props.orgId,
        fileType: 'DEFAULT',
        taskId: 0,
        treatmentId: 0,
        validFileType: true,
      }
      uploadFile(data?.uploadFile, props.orgId, uploadReq)
      handleCancel()
      console.log('DATA UPLOAD --->', data, uploadReq)
    } catch {}
  }

  const getAllDocuments = async () => {
  try {
    const response = await Axios.post(`${BASE_URL}/organisations/${props.orgId}/documents/details`, {
      fileType: 'DEFAULT',
      organisation: props.orgId,
    });

    console.log('DOC RESULT --->', response);
    
    const { data } = response;
    
    if (data) {
      console.log(data);
      setDocData(data);
    } else {
      notification.warning({
        description: 'No File Found',
      });
    }
  } catch (error) {
    console.error('Error fetching documents:', error);
  }
};


  const onFinishFailed = () => {}
  return (
    <>
      {/* <Modal
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Name"
            name="fileName"
            rules={[{ required: true, message: 'Please enter name' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Description" name="description">
            <Input />
          </Form.Item>
          <Form.Item
            label="Upload File"
            name="uploadFile"
            rules={[{ required: true, message: 'Please Upload File' }]}
          >
            <Upload
              customRequest={(e) => {
                e.onSuccess((val) => {
                  console.log('FILE VAL --->', val)
                })
                e.onError((err) => {
                  console.log('FILE err --->', err)
                })
              }}
              // onChange={handleDocUpload}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal> */}{' '}
      <h4 style={{ fontSize: '18px', color: '#33466B' }}>
        Documents
        <i
          className="fa fa-question-circle"
          style={{
            paddingLeft: '4px',
            paddingTop: '2px',
            width: '17px',
            height: '17px',
          }}
        />
      </h4>
      {/* <p>
        Add New{' '}
        <i
          className="fa fa-plus"
          style={{ color: '#4CAF50' }}
          onClick={showModal}
        />
      </p> */}
      <div className="table-main">
        <Table>
          <thead>{renderTableHeading()}</thead>
          {showMore ? (
            <tbody>{renderSlicedTableData()}</tbody>
          ) : (
            <tbody>{renderTableData()}</tbody>
          )}
        </Table>
      </div>
      <p onClick={showMoreClickHandler} className="toggle-button">
        {showMore ? 'Show More' : 'Show Less'}
      </p>
      {/* <Result status="500" title="Under Development" subTitle="" /> */}
    </>
  )
}

export default CorporateDocumentsTable
