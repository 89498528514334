import React, { Component, useState, useEffect } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Modal, Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import toLower from 'lodash/fp/toLower'
import includes from 'lodash/fp/includes'

import { getDefaultCompilerOptions, isParseTreeNode } from 'typescript'
import ButtonsHeader from '../../../common/EditDeleteTH.jsx'
import ButtonActions from '../../../common/EditDeleteButtons.jsx'
import * as moment from 'moment'
import Auth from '../../../../lib/Auth.js'
import Swal from 'sweetalert2'
import { InfoToolTip } from '../../../common/InfoTooltip.jsx'
import { notification, Modal as M1 } from 'antd'
import userContext from '../../../../context/userContext'
export default class StrategicObjectivesTable extends Component {
  static contextType = userContext
  constructor(props) {
    super(props)
    this.state = {
      tableData: [],
      users: [],
      goals: [],
      showEdit: false,
      frequency: [],
      showAdd: false,
      loading: true,
      orgId: props.orgId,
      selectedItem: null,
      showHeadings: props.showHeadings,
      objectiveType: props.objectiveType,
      objEditModal: props.objEditModal,
      page: 0,
      rowsPerPage: 5,
      totalPages: 0,
      loggedIndepartment: '',
      sessionDetails: {},
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      orgId: props.orgId,
      // users: props.users,
      goals: props.goals,
      showHeadings: props.showHeadings,
      objectiveType: props.objectiveType,
      objEditModal: props.objEditModal,
    })
  }

  componentDidMount() {
    this.getUsers()
    // this.fetchData()
    this.getgoals()
    this.getFrequency()
  }

  getFrequency() {
    axios
      .get(BASE_URL + `/organisations/${this.state.orgId}/frequencyOfReviews`, {
        params: {
          organisation: this.state.orgId,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ frequency: res.data, loading: false })
        }
      })
  }

  getPageRows = () => {
    var chunks = [],
      i = 0,
      n = this.state.tableData.length
    while (i < n) {
      chunks.push(this.state.tableData.slice(i, (i += this.state.rowsPerPage)))
    }

    return chunks[this.state.page] || this.state.tableData
  }

  getTotalPages = (tableData) => {
    var chunks = [],
      i = 0,
      n = tableData.length
    while (i < n) {
      chunks.push(tableData.slice(i, (i += this.state.rowsPerPage)))
    }
    return chunks.length
  }

  fetchData() {
    const { sessionDetails } = this.context

    let params =
      this.props.objectiveType === 'DEPARTMENT'
        ? {
            organisation: this.state.orgId,
            objectiveType: this.state.objectiveType,
            department: this.state.loggedIndepartment.id,
          }
        : {
            organisation: this.state.orgId,
            objectiveType: this.state.objectiveType,
          }

    axios
      .get(BASE_URL + `/objectives`, {
        params: params,
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            tableData: res.data,
            page: 0,
            loading: false,
            totalPages: this.getTotalPages(res.data),
          })
        }
      })
  }
async getUsers() {
  const { sessionDetails } = this.context;
  const loggedUserId = Auth.getLoggedUserId();

  if (!loggedUserId) {
    this.setState({ loading: false });
    return;
  }

  try {
    const response = await axios.get(`${BASE_URL}/organisations/${this.state.orgId}/users`, {
      params: {
        department: 0,
        team: 0,
        jobRole: 0,
        status: 0
      },
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`
      }
    });

    if (response.status !== 200 || !Array.isArray(response.data)) {
      throw new Error('Invalid response format');
    }

    const filterData = response.data.find(
      data => String(data.id) === String(loggedUserId)
    );

    if (!filterData?.department) {
      this.setState({ loading: false });
      return;
    }

    await new Promise(resolve => {
      this.setState({
        user: response.data,
        users: response.data,
        loading: false,
        loggedIndepartment: filterData.department,
        sessionDetails
      }, resolve);
    });

    await this.fetchData();

  } catch (error) {
    console.error('Error in getUsers:', error.message);
    this.setState({ loading: false });
    
  }
}

  getgoals() {
    axios
      .get(BASE_URL + `/goals`, {
        params: {
          organisation: this.state.orgId,
        },
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ goals: res.data, loading: false })
        }
      })
  }

  handleEdit = (e, item) => {
    if (
      this.state.objEditModal == false ||
      this.state.objEditModal == undefined
    ) {
      this.setState({ selectedItem: item }, () =>
        this.setState({ showEdit: true })
      )
    } else {
      this.props.editItem(item)
    }
  }

  deleteItem = (e, item) => {
    this.setState({ loading: true })
    axios
      .delete(BASE_URL + `/objectives/${item.id}`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.fetchData()
        }
      })
      .catch((e) => {
        if (
          e.response &&
          e.response?.data &&
          e.response?.data?.statusCode == '400'
        ) {
          M1.confirm({
            title: 'Confirm',
            content:
              'The Objective is linked with one or more risks. Are you sure you want to delete it ?',
            okText: 'Delete',
            onOk: () => this.confirmDelete(item.id),

            cancelText: 'Cancel',
          })
        }
      })
    this.setState({ loading: false })
  }

  confirmDelete = (id) => {
    axios
      .delete(BASE_URL + `/objectives/${id}?force=true`, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((res) => {
        this.setState({ loading: false })

        if (res.status === 200) {
          this.fetchData()
        } else {
          notification.warning({
            message: 'Unsuccessfully',
            description: '',
          })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  renderTableData() {
    return this.getPageRows().map((item, index) => {
      return (
        <tr className="table-data" key={index}>
          {/* <td>{item.year}</td> */}
          <td>{item.ref}</td>
          <td>
            {this.props.tableHeading.toLowerCase() == 'strategic goal' &&
              item.goals.map((item) => item.description).join(', ')}

            {this.props.tableHeading.toLowerCase() == 'strategic objective' &&
              item.parentObjective?.description}
            {this.props.tableHeading.toLowerCase() ==
              'departmental objective' && item.parentObjective?.description}
          </td>
          <td>{item.description}</td>
          {/* <td>{item.weight}</td> */}
          <td>
            {moment(
              `${item.startDt.day}/${item.startDt.month}/
                        ${item.startDt.year}`,
              'DD/MM/YYYY'
            ).format('DD/MM/YY')}
          </td>
          <td>
            {moment(
              `${item.targetEndDt.day}/${item.targetEndDt.month}/
                        ${item.targetEndDt.year}`,
              'DD/MM/YYYY'
            ).format('DD/MM/YY')}
          </td>
          <td>
            {
              this.state.frequency.filter(
                (freq) => freq.id == item.frequencyOfReview
              )[0]?.name
            }
          </td>
          <td>
            {this?.state?.user &&
              this?.state?.user.filter((user) => user.id === item.lead)[0]
                ?.name}
          </td>
          {Auth.getRole() !== 'View' && (
            <ButtonActions
              editAction={(e) => this.handleEdit(e, item)}
              deleteAction={(e) => this.deleteItem(e, item)}
              dataTarget=""
            />
          )}
        </tr>
      )
    })
  }

  addItem = (values) => {
    let data = values

    data.startDt = moment(data.startDt, 'DD-MM-YY').format('YYYY-MM-DD')
    data.targetEndDt = moment(data.targetEndDt, 'DD-MM-YY').format('YYYY-MM-DD')
    if (this.state?.loggedIndepartment?.id) {
      this.setState({ showAdd: false, loading: true }, () => {
        axios
          .post(
            BASE_URL + `/objectives`,
            {
              ...data,
              organisation: this.state.orgId,
              objectiveType: this.state.objectiveType,
              department: this.state.loggedIndepartment.id,
            },
            {
              headers: {
                Authorization: `Bearer ${Auth.getToken()}`,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              this.fetchData()
              Swal.fire({
                title: 'Data posted successfully',
                text: 'Your form data is now present in the table',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'OK',
              })
            }
          })
          .catch((error) => {
            this.setState({ showAdd: false, loading: false })
            console.log(error)
            Swal.fire({
              title: 'Data posted un-successfully',
              text: 'Unable to save data.',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: 'OK',
            })
          })
      })
    } else {
      this.setState({ showAdd: false })
      Swal.fire({
        title: 'Data posted un-successfully',
        text:
          'Your request can not be processed as department is not assigned to the user.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'OK',
      })
    }
  }

  editItem = (values) => {
    let data = { ...values }
    data.startDt = moment(values.startDt, 'DD-MM-YY').format('YYYY-MM-DD')
    data.targetEndDt = moment(values.targetEndDt, 'DD-MM-YY').format(
      'YYYY-MM-DD'
    )
    this.setState({ showEdit: false, loading: true }, () => {
      axios
        .put(
          BASE_URL + `/objectives/${data.id}`,
          {
            ...data,
            organisation: this.state.orgId,
          },
          {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.fetchData()
          } else {
            this.setState({
              loading: false,
            })
          }
        })
        .catch((e) => {
          this.setState({
            loading: false,
          })
        })
    })
  }
  render() {
    return (
      <>
        <div
          className={`table-container table-main ${
            this.props.tableHeading.toLowerCase() == 'strategic goal'
              ? 'px-5 my-5'
              : ''
          }
                `}
        >
          {this.state.showHeadings && (
            <>
              <h4>
                <InfoToolTip
                  title={'Strategic Objectives'}
                  text={'Strategic Objectives '}
                ></InfoToolTip>
              </h4>
              {Auth.getRole() !== 'View' && (
                <p>
                  Add New{' '}
                  <i
                    className="fa fa-plus"
                    onClick={() => this.setState({ showAdd: true })}
                  />
                </p>
              )}
            </>
          )}
          <div className="table-main">
            <table
              style={{ width: '100%' }}
              className="strategic-objectives-table"
            >
              <thead>
                <tr className="bg-table-header">
                  {/* <th>Year</th> */}
                  <th style={{ width: '5%' }}>Ref</th>
                  <th>
                    {this.props.tableHeading === 'Strategic Goal'
                      ? 'Strategic Goal'
                      : includes(toLower(this.props.tableHeading), 'indicator')
                      ? 'Indicator (Strategic/Parent)'
                      : 'Objective (Strategic/Parent)'}
                  </th>
                  <th style={{ width: '30%' }}>
                    {this.props.tableHeading === 'Strategic Goal'
                      ? 'Strategic Objective'
                      : includes(toLower(this.props.tableHeading), 'indicator')
                      ? 'Department Indicator'
                      : 'Department Objective'}
                  </th>
                  {/* <th>Weight</th> */}
                  <th style={{ width: '8%' }}>Start Date</th>
                  <th style={{ width: '8%' }}>End Date</th>
                  <th style={{ width: '10%' }}>Review Freq.</th>
                  <th style={{ width: '10%' }}>Lead</th>
                  <ButtonsHeader />
                </tr>
              </thead>
              <tbody>
                {this.state.loading ? (
                  <tr className="text-center">
                    <td colSpan={5}>Loading...</td>{' '}
                  </tr>
                ) : (
                  this.renderTableData()
                )}
              </tbody>
            </table>
          </div>

          {this.state.showAdd && (
            <AddTableItemModal
              showModal={this.state.showAdd}
              addField={this.addItem}
              orgId={this.state.orgId}
              users={this.state.users}
              goals={this.state.goals}
              frequency={this.state.frequency}
              dismissModal={() => this.setState({ showAdd: false })}
            />
          )}

          {this.state.showEdit && (
            <EditTableItemModal
              showModal={this.state.showEdit}
              editField={this.editItem}
              orgId={this.state.orgId}
              users={this.state.users}
              goals={this.state.goals}
              frequency={this.state.frequency}
              dismissModal={() => this.setState({ showEdit: false })}
              item={this.state.selectedItem}
            />
          )}
        </div>

        <div className="text-right mt-3">
          <span style={{ fontSize: '14px' }}>
            Showing page {this.state.totalPages ? this.state.page + 1 : 0} of{' '}
            {this.state.totalPages}
          </span>
          <button
            className="previous-page btn btn-primary mx-2"
            disabled={this.state.page == 0}
            onClick={() =>
              this.setState((prevState) => ({
                page: prevState.page - 1,
              }))
            }
          >
            Prev
          </button>
          <button
            disabled={
              this.state.page === this.state.totalPages - 1 ||
              this.state.totalPages === 0
            }
            className="next-page btn btn-primary"
            onClick={() =>
              this.setState((prevState) => ({
                page: prevState.page + 1,
              }))
            }
          >
            Next
          </button>
        </div>
      </>
    )
  }
}

const AddTableItemModal = (props) => {
  const [show, setModal] = useState(props.showModal)
  const submitRef = React.useRef()
  const [startDt, setStartDt] = useState(null)
  const [targetEndDt, setEndDt] = useState(null)

  const handleClose = () => {
    props.dismissModal()
  }

  const handleSave = () => {
    submitRef.current.click()
  }
  const handleEndDateChange = (e) => {
    setEndDt(moment(e).format('DD-MM-YY'))
  }

  const handleStartDateChange = (e) => {
    setStartDt(moment(e).format('DD-MM-YY'))
  }

  const formik = useFormik({
    initialValues: {
      year: '',
      ref: '',
      description: '',
      goals: [],
      weight: 0,
      lead: -1,
      organisation: props.orgId,
      reviewFrequency: -1,
    },
    validationSchema: Yup.object({
      // year: Yup.string().required('Required'),
      // ref: Yup.string().required('Required'),
      // goal: Yup.string().required('Required'),
      // description: Yup.string().required('Required'),
      // weight: Yup.string().required('Required'),
      // reviewFreq: Yup.string().required('Required'),
      // lead: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      const formData = {
        ...values,
        startDt,
        targetEndDt,
        department: 0,
        team: 0,
        actualEndDt: values.endDt,
      }
      // const goals = values.goals
      // goals.push(values.goal)
      // values.goals = goals
      props.addField({ ...formData })
    },
  })
  return (
    <Modal show={show} onHide={handleClose} className="add-item-modal">
      <Modal.Header closeButton>
        <Modal.Title>Add New</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group d-flex">
            <div className="col-6">
              <label htmlFor="year">Year</label>
              <input
                id="year"
                name="year"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.year}
              />
              {formik.touched.year && formik.errors.year ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.year}
                </div>
              ) : null}
            </div>

            <div className="col-6">
              <label htmlFor="ref">Ref</label>
              <input
                id="ref"
                name="ref"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ref}
              />
              {formik.touched.ref && formik.errors.ref ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.ref}
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group">
            <div className="col-12">
              <label htmlFor="goals">Strategic goal</label>
              <select
                style={{ height: '70px' }}
                value={formik.values.goals}
                id="goals"
                name="goals"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiple
              >
                <option value="">-</option>
                {props?.goals.map((goal, i) => (
                  <option key={i} value={goal.id}>
                    {goal.description}
                  </option>
                ))}
              </select>
              {formik.touched.goals && formik.errors.goals ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.goals}
                </div>
              ) : null}
            </div>

            <div className="col-12">
              <label htmlFor="description">Strategic Objectives</label>
              <textarea
                id="description"
                name="description"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.description}
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group d-flex">
            <div className="col-6">
              <label htmlFor="weight">Weight</label>
              <input
                id="weight"
                name="weight"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.weight}
              />
              {formik.touched.weight && formik.errors.weight ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.weight}
                </div>
              ) : null}
            </div>

            <div className="col-6">
              <label htmlFor="lead">Strategic Lead</label>
              <select
                value={formik.values.lead}
                id="lead"
                name="lead"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">-</option>
                {props.users.map((user, i) => (
                  <option key={i} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
              {formik.touched.lead && formik.errors.lead ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.lead}
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group d-flex">
            <div className="col-6">
              <label htmlFor="startDt">Start Date</label>
              <DatePicker
                value={startDt}
                name="startDt"
                onChange={(e) => handleStartDateChange(e)}
                dateFormat="dd-mm-yy"
                autoComplete="off"
              />
            </div>

            <div className="col-6">
              <label htmlFor="endDt">End Date</label>
              <DatePicker
                value={targetEndDt}
                name="endDt"
                onChange={(e) => handleEndDateChange(e)}
                dateFormat="dd-mm-yy"
                autoFill={false}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="col-12">
              <label htmlFor="reviewFreq">Review Frequency</label>
              <select
                value={formik.values.reviewFrequency}
                id="reviewFrequency"
                name="reviewFrequency"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">-</option>
                {props.frequency.map((frequency, i) => (
                  <option key={i} value={frequency.id}>
                    {frequency.name}
                  </option>
                ))}
              </select>
              {formik.touched.reviewFrequency &&
              formik.errors.reviewFrequency ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.reviewFreq}
                </div>
              ) : null}
            </div>
          </div>

          <button type="submit" ref={submitRef} className="d-none">
            Submit
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const EditTableItemModal = (props) => {
  const [show, setModal] = useState(props.showModal)
  const submitRef = React.useRef()
  const item = props.item
  const currentItemGoals = item?.goals
  const [startDt, setStartDt] = useState(
    moment(
      `${item.startDt.day}-${item.startDt.month}-${item.startDt.year}`,
      'DD-MM-YYYY'
    ).format('DD-MM-YY')
  )
  const [targetEndDt, setEndDt] = useState(
    moment(
      `${item.targetEndDt.day}-${item.targetEndDt.month}-${item.targetEndDt.year}`,
      'DD-MM-YYYY'
    ).format('DD-MM-YY')
  )

  const handleClose = () => {
    props.dismissModal()
  }

  const handleEndDateChange = (e) => {
    setEndDt(moment(e).format('DD-MM-YY'))
  }

  const handleStartDateChange = (e) => {
    setStartDt(moment(e).format('DD-MM-YY'))
  }

  const handleSave = () => {
    submitRef.current.click()
  }

  const formik = useFormik({
    initialValues: {
      year: item.year,
      ref: item.ref,
      goals: item?.goals
        ? item?.goals?.reduce((final, data) => {
            return [...final, data.id]
          }, [])
        : item?.goals,
      description: item.description,
      weight: item.weight,
      reviewFrequency:
        item.frequencyOfReview == null ? 0 : item.frequencyOfReview,
      lead: item.lead,
      organisation: props.orgId,
    },
    validationSchema: Yup.object({
      // year: Yup.string().required('Required'),
      // ref: Yup.string().required('Required'),
      // goal: Yup.string().required('Required'),
      // description: Yup.string().required('Required'),
      // weight: Yup.string().required('Required'),
      // reviewFreq: Yup.string().required('Required'),
      // lead: Yup.string().required('Required')
    }),
    onSubmit: (values) => {
      props.editField({
        ...values,
        goals: values.goals,
        startDt,
        targetEndDt,
        actualEndDate: targetEndDt,
        id: item.id,
      })
    },
  })

  return (
    <Modal show={show} onHide={handleClose} className="add-item-modal">
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group d-flex">
            <div className="col-6">
              <label htmlFor="year">Year</label>
              <input
                id="year"
                name="year"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.year}
              />
              {formik.touched.year && formik.errors.year ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.year}
                </div>
              ) : null}
            </div>

            <div className="col-6">
              <label htmlFor="ref">Ref</label>
              <input
                id="ref"
                name="ref"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ref}
              />
              {formik.touched.ref && formik.errors.ref ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.ref}
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group">
            <div className="col-12">
              <label htmlFor="goals">Strategic goal</label>
              <select
                style={{ height: '70px' }}
                value={formik.values.goals}
                id="goals"
                name="goals"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiple
              >
                <option value="">-</option>
                {props?.goals.map((goal, i) => (
                  <option key={i} value={goal.id}>
                    {goal.description}
                  </option>
                ))}
              </select>
              {formik.touched.goals && formik.errors.goals ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.goals}
                </div>
              ) : null}
            </div>

            <div className="col-12 mt-3">
              <label htmlFor="description">Strategic Objectives</label>
              <textarea
                id="description"
                name="description"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.description}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group d-flex">
            <div className="col-6">
              <label htmlFor="weight">Weight</label>
              <input
                id="weight"
                name="weight"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.weight}
              />
              {formik.touched.weight && formik.errors.weight ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.weight}
                </div>
              ) : null}
            </div>

            <div className="col-6">
              <label htmlFor="lead">Strategic Lead</label>
              <select
                value={formik.values.lead}
                id="lead"
                name="lead"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">-</option>

                {props.users.map((user, i) => (
                  <option key={i} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
              {formik.touched.lead && formik.errors.lead ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.lead}
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group d-flex">
            <div className="col-6">
              <label htmlFor="startDt">Start Date</label>
              <DatePicker
                value={startDt}
                name="startDt"
                onChange={(e) => handleStartDateChange(e)}
                dateFormat="dd-mm-yy"
                autoComplete="off"
              />
            </div>

            <div className="col-6">
              <label htmlFor="endDt">End Date</label>
              <DatePicker
                value={targetEndDt}
                name="endDt"
                onChange={(e) => handleEndDateChange(e)}
                dateFormat="dd-mm-yy"
                autoFill={false}
                autoComplete="off"
              />
            </div>
          </div>

          <div className="form-group d-flex">
            <div className="col-12">
              <label htmlFor="reviewFreq">Review Frequency</label>
              <select
                value={formik.values.reviewFrequency}
                id="reviewFrequency"
                name="reviewFrequency"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">-</option>
                {props.frequency.map((frequency, i) => (
                  <option key={i} value={frequency.id}>
                    {frequency.name}
                  </option>
                ))}
              </select>
              {formik.touched.reviewFreq && formik.errors.reviewFreq ? (
                <div className="text-center text-red pr-5">
                  {formik.errors.reviewFreq}
                </div>
              ) : null}
            </div>
          </div>

          <button type="submit" ref={submitRef} className="d-none">
            Submit
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
